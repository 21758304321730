import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSortable } from '@dnd-kit/sortable';
import { Edit, DragIndicator } from '@mui/icons-material';
import { TableRow, TableCell, Checkbox, TextField, Button, Chip } from '@mui/material';

export default function SortableItem({ item, isEditing, setEditingRowId, onEditTrajectories }) {
  const { t } = useTranslation();
  const { attributes, listeners, setNodeRef, transition, transform, isDragging } = useSortable({ id: item.identifier });

  const style = {
    transition,
    transform: transform ? `translate3d(0, ${transform.y}px, 0)` : undefined,
    zIndex: isDragging ? 1000 : 'auto',
    backgroundColor: isDragging ? '#f0f0f0' : 'inherit',
  };

  const [localItem, setLocalItem] = useState(item);

  useEffect(() => {
    if (isEditing) {
      setLocalItem(item);
    }
  }, [isEditing, item]);

  const handleSave = () => {
    Object.assign(item, localItem);
    setEditingRowId(null);
  };

  const handleChange = (field, value) => {
    setLocalItem((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <TableRow ref={setNodeRef} style={style} {...attributes}>
      <TableCell {...listeners} style={{ cursor: 'grab' }}>
        <DragIndicator />
      </TableCell>
      <TableCell>
        {isEditing ? (
          <TextField
            value={localItem.identifier}
            onChange={(e) => handleChange('identifier', e.target.value)}
            fullWidth
          />
        ) : (
          item.identifier
        )}
      </TableCell>
      <TableCell>
        <Checkbox
          checked={localItem.constraint}
          onChange={(e) => handleChange('constraint', e.target.checked)}
          disabled={!isEditing}
        />
      </TableCell>
      <TableCell>
        {isEditing ? (
          <Controller
            name={`capexRatio-${item.identifier}`}
            defaultValue={item.capexRatio}
            render={({ field }) => (
              <TextField
                {...field}
                value={localItem.capexRatio}
                onChange={(e) => handleChange('capexRatio', parseFloat(e.target.value))}
                type="number"
                inputProps={{ min: 0, max: 1, step: 0.01 }}
                fullWidth
                disabled={!isEditing}
              />
            )}
          />
        ) : (
          localItem.capexRatio.toFixed(2)
        )}
      </TableCell>
      <TableCell>
        {isEditing ? (
          <TextField
            value={localItem.budgetCategories.join(', ')}
            onChange={(e) => handleChange('budgetCategories', e.target.value.split(', '))}
            fullWidth
          />
        ) : (
          localItem.budgetCategories.map((category, index) => (
            <Chip key={index} label={category} style={{ margin: '2px' }} />
          ))
        )}
      </TableCell>
      <TableCell>
        {isEditing ? (
          <TextField value={localItem.type} onChange={(e) => handleChange('type', e.target.value)} fullWidth />
        ) : (
          item.type
        )}
      </TableCell>
      <TableCell>{item.priorityRank}</TableCell>
      <TableCell>
        {isEditing ? (
          <Button variant="outlined" onClick={handleSave}>
            {t('budgetwizard.table.save', 'Save')}
          </Button>
        ) : (
          <Button onClick={() => onEditTrajectories(item)}>
            <Edit />
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
}

SortableItem.propTypes = {
  item: PropTypes.shape({
    identifier: PropTypes.string.isRequired,
    constraint: PropTypes.bool.isRequired,
    capexRatio: PropTypes.number.isRequired,
    budgetCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
    type: PropTypes.string.isRequired,
    priorityRank: PropTypes.number.isRequired,
  }).isRequired,
  isEditing: PropTypes.bool.isRequired,
  setEditingRowId: PropTypes.func.isRequired,
  onEditTrajectories: PropTypes.func.isRequired,
};
