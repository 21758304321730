import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ChevronRight, Delete, Edit, Upload } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

export default function Trajectories({ open, closeDialog, budgetItem, onUpdateTrajectories }) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [csvError] = useState(null);
  const [groupedData, setGroupedData] = useState([]);
  const [expandedGroups, setExpandedGroups] = useState({});

  // isEditing : {groupIndex: number, rowIndex: number} ou null
  const [isEditing, setIsEditing] = useState(null);

  // Données temporaires pour la ligne en édition
  const [editingRowData, setEditingRowData] = useState({ annee: '', quantiteDisponible: '' });

  // Reference for the new row's quantity field (si nécessaire)
  const quantityRef = useRef(null);

  // State to store the initial data
  const [initialData, setInitialData] = useState([]);

  const groupConsecutiveData = (sortedData) => {
    const groups = [];
    let currentGroup = [];

    for (let i = 0; i < sortedData.length; i++) {
      const currentItem = sortedData[i];
      const currentQuantity = Number(currentItem.quantiteDisponible);
      if (i === 0 || currentQuantity !== Number(sortedData[i - 1].quantiteDisponible)) {
        if (currentGroup.length > 0) {
          groups.push(currentGroup);
        }
        currentGroup = [currentItem];
      } else {
        currentGroup.push(currentItem);
      }
    }
    if (currentGroup.length > 0) {
      groups.push(currentGroup);
    }

    return groups;
  };

  const toggleGroup = (index) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  useEffect(() => {
    if (budgetItem) {
      const initialTrajectories = budgetItem.trajectories || [];
      const sortedData = [...initialTrajectories].sort((a, b) => a.annee - b.annee);
      setData(sortedData);
      setChartData(sortedData);
      setGroupedData(groupConsecutiveData(sortedData));
      setExpandedGroups({});
      setIsEditing(null);
      setInitialData(sortedData);
    }
  }, [budgetItem]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const parsedData = [];

      const updatedData = parsedData.reduce(
        (acc, newItem) => {
          const index = acc.findIndex((item) => item.annee === newItem.annee);
          if (index !== -1) {
            acc[index] = newItem;
          } else {
            acc.push(newItem);
          }
          return acc;
        },
        [...data]
      );

      const sortedData = [...updatedData].sort((a, b) => a.annee - b.annee);
      setData(sortedData);
      setChartData(sortedData);
      setGroupedData(groupConsecutiveData(sortedData));
      setExpandedGroups({});
      setIsEditing(null);
    }
  };

  const handleAddRow = () => {
    setData((prevData) => {
      const lastYear = prevData.length > 0 ? parseInt(prevData[prevData.length - 1].annee) : new Date().getFullYear();
      const newRow = { annee: lastYear + 1, quantiteDisponible: '' };

      const index = prevData.findIndex((item) => item.annee === newRow.annee);
      if (index !== -1) {
        alert(t('trajectories.duplicateYear', 'This year already exists.'));
        return prevData;
      }

      const newData = [...prevData, newRow];
      const sortedData = newData.sort((a, b) => a.annee - b.annee);

      setChartData(sortedData);
      setGroupedData(groupConsecutiveData(sortedData));
      setExpandedGroups({});

      const newGrouped = groupConsecutiveData(sortedData);
      const { groupIndex, rowIndexInGroup } = findRowInGroups(newRow, newGrouped);
      setIsEditing({ groupIndex, rowIndex: rowIndexInGroup });
      setEditingRowData({ annee: newRow.annee, quantiteDisponible: newRow.quantiteDisponible });

      return sortedData;
    });

    setTimeout(() => {
      if (quantityRef.current) {
        quantityRef.current.focus();
      }
    }, 0);
  };

  const findRowInGroups = (row, groupedData) => {
    for (let gi = 0; gi < groupedData.length; gi++) {
      const group = groupedData[gi];
      for (let ri = 0; ri < group.length; ri++) {
        if (group[ri].annee === row.annee && group[ri].quantiteDisponible === row.quantiteDisponible) {
          return { groupIndex: gi, rowIndexInGroup: ri };
        }
      }
    }
    return { groupIndex: 0, rowIndexInGroup: 0 }; // fallback
  };

  const handleRemoveRow = (groupIndex, rowIndexInGroup) => {
    setData((prevData) => {
      const rowToDelete = groupedData[groupIndex][rowIndexInGroup];
      const newData = prevData.filter(
        (item) => !(item.annee === rowToDelete.annee && item.quantiteDisponible === rowToDelete.quantiteDisponible)
      );

      const sortedData = [...newData].sort((a, b) => a.annee - b.annee);
      setChartData(sortedData);
      setGroupedData(groupConsecutiveData(sortedData));
      setExpandedGroups({});
      setIsEditing(null);
      return sortedData;
    });

    onUpdateTrajectories && onUpdateTrajectories(data);
  };

  const handleChange = (field, value) => {
    setEditingRowData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleEdit = (groupIndex, rowIndexInGroup) => {
    // On met la ligne en édition
    setIsEditing({ groupIndex, rowIndex: rowIndexInGroup });
    const row = groupedData[groupIndex][rowIndexInGroup];
    // Initialiser editingRowData avec les valeurs de la ligne
    setEditingRowData({ annee: row.annee, quantiteDisponible: row.quantiteDisponible || '' });
  };

  const handleSaveRow = (groupIndex, rowIndexInGroup) => {
    // Appliquer les modifications de editingRowData à data
    setData((prevData) => {
      const rowToEdit = groupedData[groupIndex][rowIndexInGroup];
      const indexInData = prevData.findIndex(
        (item) => item.annee === rowToEdit.annee && item.quantiteDisponible === rowToEdit.quantiteDisponible
      );
      if (indexInData === -1) return prevData;

      const newData = [...prevData];
      newData[indexInData] = {
        ...newData[indexInData],
        annee: parseInt(editingRowData.annee, 10),
        quantiteDisponible: editingRowData.quantiteDisponible,
      };

      const sortedData = newData.sort((a, b) => a.annee - b.annee);
      setChartData(sortedData);
      setGroupedData(groupConsecutiveData(sortedData));
      setExpandedGroups({});
      setIsEditing(null);

      // Mettez à jour le parent
      onUpdateTrajectories && onUpdateTrajectories(sortedData);

      return sortedData;
    });
  };

  const handleCancel = () => {
    // On sort du mode édition sans appliquer les modifications
    setIsEditing(null);
    setEditingRowData({ annee: '', quantiteDisponible: '' });
  };

  const handleReset = () => {
    setData(initialData);
    setChartData(initialData);
    setGroupedData(groupConsecutiveData(initialData));
    setExpandedGroups({});
    setIsEditing(null);
    setEditingRowData({ annee: '', quantiteDisponible: '' });
  };

  const isRowBeingEdited = (gIndex, rIndex) => {
    return isEditing && isEditing.groupIndex === gIndex && isEditing.rowIndex === rIndex;
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>{t('trajectories.title', 'Trajectories')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid container spacing={2} flexDirection="row" mt={2} ml={0} width="100%" justifyContent="space-between">
            <Grid item xs={4} sx={{ width: '100%' }}>
              <TextField
                label={t('trajectories.budgetEnvelope', 'Enveloppe Budgetaire')}
                value={budgetItem.identifier}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField label={t('trajectories.type', 'Type')} fullWidth value={budgetItem.type} disabled />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={t('trajectories.budgetCategories', 'Categories Budgetaires')}
                value={budgetItem.budgetCategories.join(', ')}
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button variant="outlined" component="label">
              {t('trajectories.loadCsv', 'Charger Trajectoires')} <Upload />
              <input type="file" hidden onChange={handleFileUpload} />
            </Button>
            {csvError && <p style={{ color: 'red' }}>{csvError}</p>}
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('trajectories.year', 'Annee')}</TableCell>
                      <TableCell>{t('trajectories.availableQuantity', 'Quantite Disponible')}</TableCell>
                      <TableCell>{t('trajectories.actions', 'Actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {groupedData.map((group, groupIndex) => {
                      const isExpanded = expandedGroups[groupIndex] || false;
                      const groupHasMultiple = group.length > 1;

                      return (
                        <React.Fragment key={groupIndex}>
                          {group.map((row, rowIndexInGroup) => {
                            const editing = isRowBeingEdited(groupIndex, rowIndexInGroup);
                            const showRow = rowIndexInGroup === 0 || isExpanded;

                            if (!showRow) {
                              return null;
                            }

                            return (
                              <TableRow key={rowIndexInGroup}>
                                <TableCell>
                                  {rowIndexInGroup === 0 && groupHasMultiple && (
                                    <IconButton
                                      onClick={() => toggleGroup(groupIndex)}
                                      size="small"
                                      sx={{ padding: 0 }}
                                    >
                                      {isExpanded ? (
                                        <ChevronRight style={{ transform: 'rotate(90deg)' }} />
                                      ) : (
                                        <ChevronRight />
                                      )}
                                    </IconButton>
                                  )}
                                  {editing ? (
                                    <TextField
                                      value={editingRowData.annee}
                                      type="number"
                                      fullWidth
                                      onChange={(e) => handleChange('annee', e.target.value)}
                                    />
                                  ) : (
                                    <Typography
                                      sx={{ display: 'inline-block', marginLeft: rowIndexInGroup > 0 ? '30px' : '0' }}
                                    >
                                      {row.annee}
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell>
                                  {editing ? (
                                    <TextField
                                      value={editingRowData.quantiteDisponible}
                                      type="number"
                                      fullWidth
                                      inputRef={quantityRef}
                                      onChange={(e) => handleChange('quantiteDisponible', e.target.value)}
                                    />
                                  ) : (
                                    <Typography>{row.quantiteDisponible}</Typography>
                                  )}
                                </TableCell>
                                <TableCell>
                                  {editing ? (
                                    <>
                                      <Button onClick={() => handleSaveRow(groupIndex, rowIndexInGroup)}>
                                        {t('trajectories.save', 'Save')}
                                      </Button>
                                      <Button onClick={handleCancel}>{t('trajectories.cancel', 'Cancel')}</Button>
                                    </>
                                  ) : (
                                    <Button onClick={() => handleEdit(groupIndex, rowIndexInGroup)}>
                                      <Edit />
                                    </Button>
                                  )}
                                  <Button onClick={() => handleRemoveRow(groupIndex, rowIndexInGroup)}>
                                    <Delete />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container spacing={2} flexDirection="row" mt={2} ml={0} width="100%" justifyContent="space-between">
                <Button variant={'contained'} onClick={handleAddRow}>
                  {t('trajectories.addRow', 'Add Row')}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              {chartData.length > 0 && (
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={chartData}>
                    <XAxis dataKey="annee" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="quantiteDisponible" fill="#8884d8" barSize={40} />
                  </BarChart>
                </ResponsiveContainer>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReset}>{t('trajectories.reset', 'Reset')}</Button>
        <Button onClick={closeDialog}>{t('trajectories.close', 'Close')}</Button>
      </DialogActions>
    </Dialog>
  );
}

Trajectories.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  budgetItem: PropTypes.shape({
    identifier: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    budgetCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
    trajectories: PropTypes.arrayOf(
      PropTypes.shape({
        annee: PropTypes.number.isRequired,
        quantiteDisponible: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
  }).isRequired,
  onUpdateTrajectories: PropTypes.func.isRequired,
};
