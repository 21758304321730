import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DndContext, closestCenter, useSensor, useSensors, PointerSensor } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import './BudgetTable.css';
import SortableItem from './SortableItem';

export default function BudgetTable({
  budgetDataState,
  handleDragEnd,
  editingRowId,
  setEditingRowId,
  onEditTrajectories,
}) {
  const { t } = useTranslation();

  // Configure sensors to restrict movement to the Y-axis
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10, // Optional: start dragging after moving 10px
      },
      axis: 'y', // Restrict movement to the Y-axis
    })
  );

  useEffect(() => {
    // Force un re-render lorsque budgetDataState change
  }, [budgetDataState]);

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} sensors={sensors}>
      <SortableContext items={budgetDataState.map((item) => item.identifier)} strategy={verticalListSortingStrategy}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{t('budgetwizard.table.identifier', 'Identifier')}</TableCell>
                <TableCell>{t('budgetwizard.table.constraint', 'Constraint')}</TableCell>
                <TableCell>{t('budgetwizard.table.capexRatio', 'CAPEX Ratio')}</TableCell>
                <TableCell>{t('budgetwizard.table.budgetCategories', 'Budget Categories')}</TableCell>
                <TableCell>{t('budgetwizard.table.type', 'Type')}</TableCell>
                <TableCell>{t('budgetwizard.table.priorityRank', 'Priority Rank')}</TableCell>
                <TableCell>{t('budgetwizard.table.editTrajectories', 'Trajectories')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {budgetDataState.map((item) => (
                <SortableItem
                  key={item.identifier}
                  item={item}
                  isEditing={editingRowId === item.priorityRank}
                  setEditingRowId={setEditingRowId}
                  onEditTrajectories={() => onEditTrajectories(item)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </SortableContext>
    </DndContext>
  );
}

BudgetTable.propTypes = {
  budgetDataState: PropTypes.array.isRequired,
  handleDragEnd: PropTypes.func.isRequired,
  editingRowId: PropTypes.string,
  setEditingRowId: PropTypes.func,
  onEditTrajectories: PropTypes.func.isRequired,
};
