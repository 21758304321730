import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid } from '@mui/material';
import BudgetTable from './BudgetTable';
import GeneralConfiguration from './GeneralConfiguration';
import SpecificConfiguration from './SpecificConfiguration';

function arrayMove(array, fromIndex, toIndex) {
  const newArray = [...array];
  const [movedItem] = newArray.splice(fromIndex, 1);
  newArray.splice(toIndex, 0, movedItem);
  return newArray;
}

export default function BudgetWizard({
  open,
  closeDialog,
  onConfirm,
  categories = [],
  budgetData = [],
  onEditTrajectories,
}) {
  const { t } = useTranslation();
  const methods = useForm({ mode: 'onChange' });
  const { formState } = methods;
  const [includedTags, setIncludedTags] = useState([]);
  const [excludedTags, setExcludedTags] = useState([]);
  const [editingRowId, setEditingRowId] = useState(null);
  const [showExternalExpense, setShowExternalExpense] = useState(true);
  const [showWorkforce, setShowWorkforce] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const [budgetDataState, setBudgetDataState] = useState(budgetData);
  const [initialBudgetData, setInitialBudgetData] = useState([]);

  useEffect(() => {
    // Store the initial order of budget data
    setInitialBudgetData(budgetData);
    setBudgetDataState(budgetData);
  }, [budgetData]);

  useEffect(() => {
    // Filter budget data based on user selections
    const filteredData = budgetData.filter((item) => {
      if (item.type === 'Suppliers' && !showExternalExpense) return false;
      if (item.type === 'Workforce' && !showWorkforce) return false;
      if (searchTerm && !item.identifier.toLowerCase().includes(searchTerm.toLowerCase())) return false;

      // Check for included categories
      if (includedTags.length > 0 && !includedTags.some((tag) => item.budgetCategories.includes(tag))) return false;

      // Check for excluded categories
      if (excludedTags.some((tag) => item.budgetCategories.includes(tag))) return false;

      return true;
    });
    setBudgetDataState(filteredData);
  }, [budgetData, showExternalExpense, showWorkforce, searchTerm, includedTags, excludedTags]);

  const addNewRow = () => {
    const newRow = {
      identifier: ``,
      constraint: false,
      capexRatio: 0,
      budgetCategories: [],
      type: '',
      priorityRank: budgetDataState.length + 1,
    };
    setBudgetDataState((prevState) => [...prevState, newRow]);
    setEditingRowId(newRow.priorityRank);
  };

  const resetOrder = () => {
    // Reset to the initial order
    setBudgetDataState(initialBudgetData);
  };

  const confirm = () => {
    console.log('Budget Data:', budgetDataState);
    onConfirm(budgetDataState);
  };

  const handleTagChange = (type, event, values) => {
    if (type === 'include') {
      setIncludedTags(values);
      setExcludedTags(excludedTags.filter((tag) => !values.includes(tag)));
    } else {
      setExcludedTags(values);
      setIncludedTags(includedTags.filter((tag) => !values.includes(tag)));
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const oldIndex = budgetDataState.findIndex((item) => item.identifier === active.id);
    const newIndex = budgetDataState.findIndex((item) => item.identifier === over.id);

    const newBudgetDataState = arrayMove(budgetDataState, oldIndex, newIndex);

    // Update priorityRank based on new order
    const updatedBudgetDataState = newBudgetDataState.map((item, index) => ({
      ...item,
      priorityRank: index + 1,
    }));

    setBudgetDataState(updatedBudgetDataState);
  };

  const updateBudgetDataState = (newState) => {
    setBudgetDataState(newState);
  };

  return (
    <FormProvider {...methods}>
      <Dialog open={open} fullWidth maxWidth="lg">
        <DialogTitle>{t('budgetwizard.title', 'Budget Wizard')}</DialogTitle>
        <DialogContent>
          <Grid container gap={1} flexDirection="column">
            <GeneralConfiguration />
            <SpecificConfiguration
              categories={categories}
              includedTags={includedTags}
              excludedTags={excludedTags}
              handleTagChange={handleTagChange}
              showExternalExpense={showExternalExpense}
              setShowExternalExpense={setShowExternalExpense}
              showWorkforce={showWorkforce}
              setShowWorkforce={setShowWorkforce}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
            <BudgetTable
              budgetDataState={budgetDataState}
              handleDragEnd={handleDragEnd}
              updateBudgetDataState={updateBudgetDataState}
              editingRowId={editingRowId}
              setEditingRowId={setEditingRowId}
              onEditTrajectories={onEditTrajectories}
            />
            <Grid container justifyContent="space-between">
              <Button variant="outlined" onClick={resetOrder}>
                {t('budgetwizard.buttons.resetOrder', 'Reset Order')}
              </Button>
              <Button variant="outlined" onClick={addNewRow}>
                {t('budgetwizard.buttons.addRow', 'Add Row')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>{t('budgetwizard.buttons.cancel', 'Cancel')}</Button>
          <Button variant="contained" disabled={formState.isValid} onClick={confirm}>
            {t('budgetwizard.buttons.save', 'Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}

BudgetWizard.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  categories: PropTypes.array,
  budgetData: PropTypes.array,
  onEditTrajectories: PropTypes.func.isRequired,
};
