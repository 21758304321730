import React, { useState } from 'react';
import { Button } from '@mui/material';
import BudgetWizard from './BudgetWizard';
import budgetData from './BudgetWizard.mock.json';
import Trajectories from './Trajectories/Trajectories';

function BudgetWizardContainer() {
  const [openTrajectories, setOpenTrajectories] = useState(false);
  const [selectedBudgetItem, setSelectedBudgetItem] = useState(null);
  const [budgetDataState, setBudgetDataState] = useState(budgetData);

  const handleConfirm = (data) => {
    console.log('Confirmed data:', data);
  };

  const handleEditTrajectories = (item) => {
    setSelectedBudgetItem(item);
    setOpenTrajectories(true);
  };

  const handleUpdateTrajectories = (updatedTrajectories) => {
    setBudgetDataState((prevState) =>
      prevState.map((item) =>
        item.identifier === selectedBudgetItem.identifier ? { ...item, trajectories: updatedTrajectories } : item
      )
    );
  };

  const resetOrder = () => {
    setBudgetDataState([...budgetData].sort((a, b) => a.priorityRank - b.priorityRank));
  };

  return (
    <>
      <BudgetWizard
        open={true}
        closeDialog={() => console.log('Dialog closed')}
        onConfirm={handleConfirm}
        categories={['Category1', 'Category2', 'Category3', 'Category4', 'Category5']}
        budgetData={budgetDataState}
        onEditTrajectories={handleEditTrajectories}
      />
      {selectedBudgetItem && (
        <Trajectories
          open={openTrajectories}
          closeDialog={() => setOpenTrajectories(false)}
          budgetItem={selectedBudgetItem}
          onUpdateTrajectories={handleUpdateTrajectories}
        />
      )}
      <Button onClick={resetOrder}>Reset Order</Button>
    </>
  );
}

export default BudgetWizardContainer;
