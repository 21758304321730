import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Chip,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

export default function SpecificConfiguration({
  categories,
  includedTags,
  excludedTags,
  handleTagChange,
  showExternalExpense,
  setShowExternalExpense,
  showWorkforce,
  setShowWorkforce,
  searchTerm,
  setSearchTerm,
}) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);

  const availableForInclude = categories.filter(
    (category) => !excludedTags.includes(category) && !includedTags.includes(category)
  );
  const availableForExclude = categories.filter(
    (category) => !includedTags.includes(category) && !excludedTags.includes(category)
  );

  return (
    <Accordion elevation={0} square expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h8" fontWeight="bold">
          {t('budgetwizard.specificConfiguration.title', 'Specific Configuration')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={t('budgetwizard.specificConfiguration.search', 'Search')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              options={availableForInclude}
              value={includedTags}
              onChange={(event, values) => handleTagChange('include', event, values)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => <Chip key={index} label={option} {...getTagProps({ index })} />)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('budgetwizard.specificConfiguration.includeCategories', 'Include Categories')}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              options={availableForExclude}
              value={excludedTags}
              onChange={(event, values) => handleTagChange('exclude', event, values)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => <Chip key={index} label={option} {...getTagProps({ index })} />)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('budgetwizard.specificConfiguration.excludeCategories', 'Exclude Categories')}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox checked={showExternalExpense} onChange={(e) => setShowExternalExpense(e.target.checked)} />
              }
              label={t('budgetwizard.specificConfiguration.externalExpense', 'Dépense Externe')}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={<Checkbox checked={showWorkforce} onChange={(e) => setShowWorkforce(e.target.checked)} />}
              label={t('budgetwizard.specificConfiguration.workforce', 'Main d’oeuvre')}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

SpecificConfiguration.propTypes = {
  categories: PropTypes.array.isRequired,
  includedTags: PropTypes.array.isRequired,
  excludedTags: PropTypes.array.isRequired,
  handleTagChange: PropTypes.func.isRequired,
  showExternalExpense: PropTypes.bool.isRequired,
  setShowExternalExpense: PropTypes.func.isRequired,
  showWorkforce: PropTypes.bool.isRequired,
  setShowWorkforce: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
};
